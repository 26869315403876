<template>
  <v-dialog
    v-model="dialog"
    max-width="1320px"
    max-height="800px"
    width="unset"
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="
          $user.get().role === 'admin' || $user.get().role === 'consultoria'
        "
        color="blue white--text"
        text--lighten
        v-bind="$attrs"
        v-on="on"
        @click="reset"
      >
        Importar Planilha
      </v-btn>
    </template>
    <v-card justify="center" align="center">
      <v-toolbar color="green" text--lighten dark>
        <v-col cols="11.5">
          <span class="headline">{{ dialogTitle }}</span>
        </v-col>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container v-if="sendData">
        <v-card-text>
          <div class="d-flex justify-end pb-2">
            <div
              v-if="!loading && planilha != null && form.agroindustria"
              style="height: 33px"
            >
              <v-btn
                title="Limpar tabela"
                class="card-icon align-self-end mx-1"
                icon
                @click="clearTable()"
              >
                <v-icon color="red"> mdi-broom </v-icon>
              </v-btn>
            </div>
            <config-menu
              :columns="columns"
              :template="template"
              :template-id="templateId"
              @save="posConfig"
            />
          </div>
          <v-card
            v-if="planilhaLoaded"
            outlined
            class="d-flex flex-column align-center justify-space-around"
          >
            <v-data-table
              :headers="headers"
              :items="planilha.data"
              :items-per-page="10"
              class="text-no-wrap"
              height="400"
              fixed-header
            >
              <template>
                <thead>
                  <tr>
                    <th
                      v-for="item in headers"
                      :id="`head-${item.text}`"
                      :key="item.text"
                      class="pt-2"
                    >
                      {{ item.text }}
                      <v-select dense />
                    </th>
                  </tr>
                </thead>
              </template>
              <template
                v-for="(slot, index) in slots"
                v-slot:[slot]="{ value: cell }"
              >
                <v-edit-dialog
                  v-if="cell"
                  :key="slot"
                  :return-value.sync="cell.value"
                  @open="validateMapCell(headers[index].map, cell)"
                  @close="validateMapCell(headers[index].map, cell)"
                >
                  {{ cell ? cell.value : null }}
                  <v-tooltip right transition="fade-transition" color="#F55253">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-show="cell.err"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert-octagon
                      </v-icon>
                    </template>
                    <span class="font-weight-medium">{{
                      cell.msg && cell.msg.length ? cell.msg[0] : '* Inválido'
                    }}</span>
                  </v-tooltip>
                  <template v-slot:input>
                    <v-text-field
                      v-model="cell.value"
                      label="Edit"
                      :error="cell.err"
                      :error-messages="errors"
                      single-line
                      counter
                    />
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small color="red darken-3" @click="handleDelete(item)">
                  mdi-trash-can-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
          <base-file-drop
            v-else
            ref="file-drop"
            width="800px"
            accept=".xlsx"
            @files-selected="handleFiles"
          >
            <v-card
              outlined
              class="pb-10 pt-6 d-flex flex-column align-center justify-space-around"
              height="250px"
            >
              <v-btn
                icon
                color="primary"
                elevation="0"
                x-large
                @click="selectFiles"
              >
                <v-icon x-large> mdi-file-upload </v-icon>
              </v-btn>

              <span> <b>Escolha</b> um arquivo do disco</span>
              <span> ou </span>
              <span> <b>arraste</b> um arquivo para cá </span>
            </v-card>
          </base-file-drop>
        </v-card-text>
        <v-card-text>
          <span class="headline">Escolha uma Agroindústria</span>
          <v-col cols="6">
            <v-autocomplete
              v-model="form.agroindustria"
              autocomplete="autocomplete_off_hack_xfr4!k"
              label="Agroindustria *"
              :items="agroindustrias"
              :rules="requiredRules"
              item-text="name"
              item-value="id"
              :disabled="!loadingData.agroindustria"
              :loading="!loadingData.agroindustria"
              required
            />
          </v-col>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="error" @click="dialog = false">
            <v-icon dark left> mdi-minus-circle </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            :disabled="planilha === null || !!!form.agroindustria"
            color="success"
            @click="submit"
          >
            <v-icon dark left> mdi-checkbox-marked-circle </v-icon>
            Importar
          </v-btn>
        </v-card-actions>
      </v-container>
      <v-container v-else style="width: 650px">
        <v-card>
          <v-card-title class="text-h5">
            Estamos importando os dados, aguarde...
          </v-card-title>
          <v-card-text width="300px"
            >Aguarde, isso pode levar alguns minutos. Não saia desta
            página!</v-card-text
          >
          <v-row justify="center" align="center">
            <v-col cols="10" justify="center" align="center">
              <v-progress-linear
                height="25"
                :value="form.progress"
                stream
                color="teal"
                ><strong
                  >{{ Math.ceil(form.progress) }}%</strong
                ></v-progress-linear
              >
              <v-card-title class="text-h6">
                {{ form.register }} registros foram importados
              </v-card-title>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              :disabled="!sendData"
              @click="dialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import Swal from 'sweetalert2'
import masker from 'vue-the-mask/src/masker'
import tokens from 'vue-the-mask/src/tokens'
import axios from '../../api/axios'
import { Worksheet } from '../../utils/Worksheet'
import formRules from '../../utils/formRules'
import { currencyFormatter } from '../../utils/formatter'
import { validarCNPJ } from '../../utils/validacoes'
import ConfigMenu from './ConfigMenu'
import Sender from './Sender'
import types from './Types'
import { WorksheetTemplate } from './WorksheetTemplate'
import templates from './templates'
import fazendaFields from './templates/fazenda.json'

export default {
  components: {
    ConfigMenu,
  },
  props: {
    dialogTitle: {
      type: String,
      default: 'Upload de Arquivos',
    },
    templateId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sendData: true,
      dialog: false,
      hover: true,
      planilha: null,
      errors: null,
      template: { data: [] },
      loading: true,
      meta: null,
      file: null,

      requiredRules: [v => !!v || '* Obrigatório'],
      loadingData: {
        agroindustria: false,
      },

      form: {
        agroindustria: '',
        progress: 0,
        register: 0,
      },
      agroindustrias: [{ name: 'Carregando', id: 0 }],
    }
  },
  computed: {
    planilhaLoaded() {
      return !!(this.planilha && this.headers)
    },
    headers() {
      const f = this.template ? this.template.headers : null
      if (f) {
        f.unshift({ text: '', value: 'actions', sortable: false, width: '50' })
      }
      return f
    },
    slots() {
      return this.headers.map(h => `item.${h.value}`)
    },
    columns() {
      return this.planilha ? this.planilha.columns : []
    },
  },
  created() {
    this.loading = true
    this.getTemplate()
    this.loadAgroindustrias()
  },
  methods: {
    loadAgroindustrias() {
      this.api.get.agroindustrias().then(data => {
        this.agroindustrias = data
        this.agroindustrias = this.agroindustrias.filter(item => {
          return item.pessoa?.pessoajuridica?.NomeFantasia
        })
        this.agroindustrias = this.agroindustrias.map(item => {
          return {
            name: item.pessoa.pessoajuridica.NomeFantasia,
            id: item.CodAgroindustria,
          }
        })
        this.loadingData.agroindustria = true
      })
    },

    handleDelete(item) {
      const index = this.planilha.data.findIndex(r => r.id === item.id)
      this.planilha.data.splice(index, 1)
      if (this.planilha.data.length === 0) {
        this.reset()
      }
    },
    async posConfig() {
      if (!this.planilha) return

      await this.getTemplate()
      this.planilha = new Worksheet(this.file, this.template)
      this.validateTable()
    },
    clearTable() {
      Swal.fire({
        title: 'Tem certeza que deseja limpar a tabela?',
        icon: 'question',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: '#109010',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Limpar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        focusConfirm: false,
        preConfirm: () => {
          this.reset()
          return true
        },
      })
    },
    reset() {
      this.form.agroindustria = ''
      this.planilha = null
      this.template = { data: [] }
      this.$emit('update:template', this.template)
      this.getTemplate()
    },
    async getTemplate() {
      let resp = localStorage.getItem('worksheets')

      if (!resp) {
        resp = fazendaFields
      }

      this.meta = JSON.parse(resp)['0'].meta
      this.template = new WorksheetTemplate(JSON.parse(resp))
      this.templateId = 3

      this.$emit('update:template', this.template)
      this.loading = false
    },
    selectFiles() {
      this.$refs['file-drop'].activate()
    },
    handleFiles($event) {
      Swal.fire({
        title: 'Lendo planilha...',
      })
      Swal.showLoading()
      const reader = new FileReader($event[0])

      this.file = ''

      reader.onload = e => {
        try {
          this.file = e.target.result
          this.planilha = new Worksheet(e.target.result, this.template)
          this.validateTable()
        } finally {
          Swal.hideLoading()
          Swal.update({
            title: 'Planilha lida com sucesso!',
            icon: 'success',
          })
        }
      }

      reader.readAsArrayBuffer($event[0])
    },
    constructErrors(data) {
      this.errors = data.map(row =>
        Object.fromEntries(Object.keys(row).map(key => [key, false])),
      )
    },
    validateCell(field, cell, bypass = false) {
      if (field.required && !cell.value) {
        cell.err = true
        cell.msg = ['O campo deve ser preenchido.']
        return cell.msg[0]
      }
      if (!(field && field.type && cell)) {
        return { valid: true, msg: [] }
      }
      if (cell.err && cell.msg && !bypass) {
        return cell.msg[0]
      }

      let errors = []
      for (let type of field.type.split(',')) {
        const state = { valid: true, msg: [] }
        if (!types[type]) {
          continue
        }

        if (type === 'hora') {
          cell.value = cell.value.length < 5 ? '0' + cell.value : cell.value
        }

        if (type === 'numero') {
          cell.value = cell.value.replace(',', '.')
        }

        if (type === 'currency') {
          const cellStr = String(cell.value)
          const iPoint = cellStr.indexOf('.')
          const iComma = cellStr.indexOf(',')
          if (iPoint > iComma) {
            cell.value = currencyFormatter(cellStr.replaceAll(',', ''))
          } else {
            cell.value = currencyFormatter(
              cellStr.replaceAll('.', '').replaceAll(',', '.'),
            )
          }
        }

        if (type === 'date' && !cell.formatted) {
          cell.value = cell.value.split('.')
          if (cell.value.length > 1) {
            cell.value.splice(1, 0, '01')
          }
          cell.value = new Date(cell.value)
          cell.value = cell.value.toLocaleString('pt-BR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
          cell.formatted = true
        }

        if (
          type === 'telefone' ||
          type === 'cpf' ||
          type === 'cnpj' ||
          type === 'cpf_cnpj'
        ) {
          cell.value = cell.value ? String(cell.value) : ''
        }

        if (type === 'cpf_cnpj') {
          type = validarCNPJ(cell.value) ? 'cnpj' : 'cpf'
        }

        const { rules = null, mask = null } = types[type]
        if (mask && !(Array.isArray(mask) && !mask.length)) {
          cell.value = masker(cell.value, mask, true, tokens)
        }

        const result = rules.reduce(({ valid, msg }, cur) => {
          if (!valid) {
            return { valid, msg }
          }

          const [rule, ...args] = Array.isArray(cur) ? cur : [cur]
          let res
          if (!field.required && !cell.value) {
            res = true
          } else
            res = args.length
              ? formRules[rule](...args)(cell.value)
              : formRules[rules](cell.value)

          // IMPORTANTE: Nesse ponto, o retorno das funções de validação é tratado de acordo com o seu tipo,
          // que pode ser string (msg de erro) ou boolean
          return typeof res !== 'boolean'
            ? { valid: false, msg: [...msg, res] }
            : { valid: valid && !!res, msg }
        }, state)

        if (result.valid) {
          cell.msg = null
          cell.err = false
          return state
        } else errors = [...errors, ...result.msg]
      }

      cell.err = true
      cell.msg = errors
      return errors[0]
    },
    validateMapCell(map, cell) {
      const field = this.template.field(map)
      cell.err = false
      cell.msg = ['']
      const result = this.validateCell(field, cell, true)
      this.errors = result.msg
      return result
    },
    async submit() {
      if (this.planilha === null) {
        Swal.fire({
          title: 'Escolha um arquivo para prosseguir com a importação!',
          icon: 'warning',
          showConfirmButton: true,
        })
        return
      }

      let haveError = false

      this.planilha.data.map(el =>
        Object.values(el).map(erro => {
          if (erro.err === true) haveError = true
        }),
      )

      if (haveError) {
        Swal.fire({
          title: 'Corrija os erros antes de prosseguir com a importação!',
          icon: 'warning',
          showConfirmButton: true,
        })
        return
      }

      const imported = this.meta.field
      return templates[imported]
        .submit(this.template, this.planilha, this.form.agroindustria)
        .then(result => {
          this.sendData = false
          this.seender(result)
            .then(result => {
              this.sendData = true
              this.dialog = false

              if (result.result.erros.length > 0) {
                let msgErro = ''
                Object.values(result.result.erros[0]).map(el => {
                  msgErro += el.error
                  Object.keys(el)
                    .filter(erro => erro !== 'error')
                    .map(erro => {
                      msgErro += '(' + el[erro] + ')'
                    })
                  msgErro += ', <br/>'
                })

                Swal.fire({
                  icon: 'warning',
                  title: 'Atenção! Alguns registros não foram importados.',
                  html: msgErro,
                }).then(() => {
                  this.$router.push({
                    path: `/${this.$user.get().role}/dashboard`,
                  })
                })
                return
              }

              Swal.fire({
                title: 'Importação realizada com sucesso!',
                icon: 'success',
                showConfirmButton: true,
              }).then(() => {
                this.$router.push({
                  path: `/${this.$user.get().role}/dashboard`,
                })
              })
            })
            .catch(() => {
              this.sendData = true
              Swal.fire({
                title: 'Erro ao enviar dados para o servidor! Tente novamente.',
                icon: 'error',
                showConfirmButton: true,
              })
            })
        })

        .catch(error => {
          Swal.hideLoading()
          Swal.fire({
            icon: 'warning',
            title: 'Atenção!',
            // text: 'Não foi possível importar a planilha, confira os dados.',
            text: error.errors ? error.errors : error.message,
          })
        })
    },

    validateTable() {
      const templateFields = this.template.fields
      this.planilha.data.forEach(row => {
        Object.keys(templateFields).forEach(key => {
          this.validateCell(templateFields[key], row[key])
        })
      })
    },

    /* eslint-disable */
    async createMultipleFarm(submitPlan) {
      return axios.post('/fazendas/multi', {
        fazendas: submitPlan,
        agroindustria: this.form.agroindustria,
      })
    },

    async seender(data) {
      this.form.progress = 0
      this.form.register = 0

      const seender = new Sender(data, 50) // 50 é o limite de registros por requisição
      const response = await seender.send(this.form, this.createMultipleFarm)

      return response
    },
  },
}
</script>
